import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'modules/CustomerUI/TextField';

const ManualEntry = ({ addressFields = {}, onChangeField }) => {
  const {
    flatNumber,
    buildingName,
    buildingNumber,
    street,
    town,
  } = addressFields;

  const handleChange = (event) => {
    onChangeField(event.target.name, event.target.value);
  };

  return (
    <>
      <TextField
        dataTestId="AddressPickerUIHousenumber"
        label="Flat Number"
        fullWidth
        value={flatNumber}
        name="flatNumber"
        onChange={handleChange}
      />
      <TextField
        dataTestId="AddressPickerUIAddressline1"
        label="Building Name"
        fullWidth
        value={buildingName}
        name="buildingName"
        onChange={handleChange}
      />
      <TextField
        dataTestId="AddressPickerUIAddressline2"
        label="Building Number"
        fullWidth
        required
        value={buildingNumber}
        name="buildingNumber"
        onChange={handleChange}
      />
      <TextField
        dataTestId="AddressPickerUIStreet"
        label="Street"
        fullWidth
        required
        value={street}
        name="street"
        onChange={handleChange}
      />
      <TextField
        dataTestId="AddressPickerUICity"
        label="City/Town"
        fullWidth
        required
        value={town}
        name="town"
        onChange={handleChange}
      />
    </>
  );
};

ManualEntry.propTypes = {
  onChangeField: PropTypes.func.isRequired,
  addressFields: PropTypes.shape({
    flatNumber: PropTypes.string,
    buildingName: PropTypes.string,
    buildingNumber: PropTypes.string,
    street: PropTypes.string,
    town: PropTypes.string,
  }),
};

export default ManualEntry;
